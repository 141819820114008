<template>

  <div class="wrapper">
    <div class="animated fadeIn">
      <!-- 판매관리 --------------------------------------------------------------------------------------------->
      <BCard>
        <BCard class="mb-1" footer-tag="footer" header-tag="header">

          <div slot="header">
            <BIconGiftFill/>
            <strong> 제품주문원장 </strong>
            <div class="card-header-actions">
              <small class="text-muted">주문을 생성, 승인 관리 및 승인된 주문에 대하여 인증서를 전송합니다.</small>
            </div>
          </div>

          <BRow class="mb-2">
            <BCol>
              <!--                            <strong>Total Records: {{ rows.length }}</strong>-->
              <BInputGroup>
                <BFormSelect v-model="selectedPrd"
                             :options="prdOpts"
                             size="sm"/>
                <BInputGroupAppend>
                  <BButton size="sm" variant="warning" @click="insertSalesOrder">
                    <BIconPlusCircleFill/>
                    추가
                  </BButton>
                </BInputGroupAppend>
              </BInputGroup>
            </BCol>

            <BCol sm="7">
              <BInputGroup size="sm">
                <BInputGroupPrepend>
                  <BFormSelect v-model="dateGbn"
                               :options="dateGbnOpt"
                               size="sm"
                               @input="setDateGbn"/>
                </BInputGroupPrepend>
                <BInputGroupAppend>
                  <BFormInput v-model="fromDate"
                              v-b-tooltip.hover
                              size="sm"
                              title="Enter 검색" type="date"
                              @input="setPayDate"
                              @keyup.enter="getPrdOrderList"/>
                </BInputGroupAppend>

                <BInputGroupAppend>
                  <BFormInput v-model="toDate"
                              v-b-tooltip.hover
                              size="sm"
                              title="Enter 검색" type="date"
                              @input="setPayDate"
                              @keyup.enter="getPrdOrderList"/>
                </BInputGroupAppend>
                <BInputGroupAppend>
                  <BFormSelect v-model="searchField" :options="searchOpts"
                               class="ml-3"
                               size="sm"/>
                </BInputGroupAppend>
                <BInputGroupAppend>
                  <BFormInput v-model="searchWord"
                              v-b-tooltip.hover
                              size="sm"
                              title="Enter 검색" type="text"
                              @keyup.enter="getPrdOrderList"/>
                  <BButton variant="primary" @click="getPrdOrderList">검 색</BButton>
                </BInputGroupAppend>

              </BInputGroup>

            </BCol>
            <BCol sm="2">
              <BButtonGroup>
                <BButton size="sm" variant="primary" @click="getPrdOrderList">
                  <BIconArrowRepeat/>
                  Refresh
                </BButton>
                <BButton class="ml-1" size="sm" variant="info" @click="exportExcel">
                  <BIconFileSpreadsheetFill/>
                  다운로드
                </BButton>
              </BButtonGroup>
            </BCol>

          </BRow>

          <BRow>
            <BCol>
              <vue-excel-editor
                  ref="orderGrid"
                  v-model="rows"
                  :localized-label="$localizedLabel"
                  :page="numOfRecord"
                  :readonly-style="{backgroundColor:'#EFE'}"
                  class="mb-1"
                  filter-row
                  width="100%"
                  @select="selectSalesOrder"
                  @update="updateSalesOrder">
                <vue-excel-column field="ord_no" key-field label="주문번호" readonly type="number" width="60px"/>
                <vue-excel-column field="sales_id" label="판매ID" readonly type="string" width="80px" :to-text="v=>{return v?v.slice(-6).toUpperCase():'N/A'}"/>
                <vue-excel-column field="sales_code" label="판매코드" readonly width="90px"/>
                <vue-excel-column field="ord_dt" label="주문일시" type="datetime" width="140px"/>
                <vue-excel-column field="cu_id" label="주문자ID" width="110px"/>
                <vue-excel-column :options="prdCodeOpts" field="prd_code" label="주문상품" type="map" width="200px"/>
                <vue-excel-column field="warranty_mon" label="WT" type="number" width="40px"/>
                <vue-excel-column :validate="validateCell" field="qty" label="수량" type="number" width="50px"/>
                <vue-excel-column field="unit_price" label="단가" readonly type="number" width="80px"/>
                <vue-excel-column field="price" label="금액" readonly type="number" width="80px"/>
                <vue-excel-column field="pay_amt" label="결제금액" readonly type="number" width="90px"/>
                <vue-excel-column :options="payStatOpts" field="pay_stat" label="지불상태" readonly type="map"
                                  width="80px"/>
                <vue-excel-column :options="payTypeOpts" field="pay_type" label="결제" type="map" width="90px"/>
                <vue-excel-column field="trs_no" label="TRS#" readonly type="number" width="60px"/>
                <vue-excel-column field="pay_dt" label="결제일" type="date" width="88px"/>
                <vue-excel-column field="appr_yn" label="승인" readonly type="checkYN" width="50px"/>
                <vue-excel-column field="appr_dt" label="승인일시" readonly type="datetime" width="140px"/>
                <vue-excel-column field="cncl_yn" label="취소" readonly type="checkYN" width="40px"/>
                <vue-excel-column field="cncl_dt" label="취소일" readonly type="date" width="90px"/>
                <vue-excel-column field="name" label="성명" width="80px"/>

                <vue-excel-column field="cu_seq" label="ID#" width="65px"/>
                <vue-excel-column field="phone_no" label="연락처" width="130px"/>
                <vue-excel-column field="email" label="e-mail" width="170px"/>
                <vue-excel-column field="comp_name" label="회사명" width="100px"/>
                <vue-excel-column field="pr_name" label="대표자명" width="90px"/>
                <vue-excel-column field="address" label="사업장주소" width="130px"/>
                <vue-excel-column field="biz_kind" label="업태/업종" width="110px"/>
                <vue-excel-column field="cu_id" label="ID" width="70px"/>
                <vue-excel-column field="comp_no" label="사업자번호" width="110px"/>
                <vue-excel-column :options="sendStatOpts" field="send_stat" label="발송상태" readonly type="map"
                                  width="80px"/>
                <vue-excel-column field="send_dt" label="발송일시" readonly type="datetime" width="140px"/>
                <vue-excel-column :options="taxStatOpts" field="tax_stat" label="계산서" type="map" width="80px"/>
                <vue-excel-column field="tax_dt" label="발행일" readonly type="date" width="80px"/>
                <!--                                <vue-excel-column field="cncl_dt" label="취소일시" type="datetime" width="80px"/>-->
                <!--                                <vue-excel-column field="cncl_desc" label="취소사유" width="100px"/>-->
                <vue-excel-column field="description" label="부가정보" width="140px"/>
                <!--                                <vue-excel-column field="upd_dt" label="수정일" type="date" width="90px" readonly/>-->
                <vue-excel-column field="reg_dt" label="등록일" readonly type="date" width="95px"/>
              </vue-excel-editor>
            </BCol>
          </BRow>

          <BRow>
            <BCol>
              <BButtonGroup>
                <BButton variant="warning" @click="approvalOrder">
                  <BIconCartPlusFill/>
                  주문승인
                </BButton>
                <BButton class="ml-1" variant="primary" @click="sendDocument">
                  <BIconJournalMedical/>
                  인증서 전송
                </BButton>
                <BButton class="ml-1" variant="danger" @click="deleteSalesOrder">
                  <BIconTrashFill/>
                  주문삭제
                </BButton>
              </BButtonGroup>
            </BCol>

            <BCol>
              <BProgress :max="totalCount" :value="progCount" height="2rem" show-value variant="warning"/>
            </BCol>
          </BRow>


        </BCard>


      </BCard>

    </div>
  </div>
</template>


<script>
//-------------------------------------------------------------------------------------------------
import {alertSync, alertConfirm, apiCall, cloneVar, checkBisNo, toastSync, alertWarn} from '../../../common/utils';
import qs from 'querystring';
import moment from "moment";

let _order = {
  ord_no: null,
  cu_id: null,
  cu_seq: null,
  prd_code: null,
  unit_price: null,
  qty: null,
  warranty_mon: null,
  price: null,
  pay_amt: null,
  pay_type: null,
  user_seq: null,
  ord_dt: null,
  email: null,
  name: null,
  phone_no: null,
  comp_no: null,
  comp_name: null,
  pr_name: null,
  biz_kind: null,
  address: null,
  zip_code: null,
  appr_yn: null,
  appr_dt: null,
  send_stat: null,
  send_dt: null,
  pay_stat: null,
  pay_dt: null,
  cncl_yn: null,
  cncl_dt: null,
  cncl_desc: null,
  description: null,
  reg_dt: null,
  upd_dt: null,
  reg_id: null,
  upd_id: null,
};

//----------------------------------------------------------------------------------------------------
export default {
  name: 'Order',
  data() {
    return {
      prdCodeOpts: {},
      selectedPrd: null,
      prdOpts: [],
      prdInfo: {},
      searchOpts: [
        {value: 'name', text: '이름/회사명'},
        {value: 'comp_name', text: '회사명'},
        {value: 'email', text: '전자우편'},
        {value: 'phone_no', text: '전화번호'},
        {value: 'cu_id', text: '아이디'},
        {value: 'prd_code', text: '제품코드'}
      ],
      dayOpts: [
        {value: 7, text: '1주일'},
        {value: 14, text: '2주일'},
        {value: 30, text: '30일'},
        {value: 90, text: '90일'},
        {value: 180, text: '180일'},
        {value: null, text: '전체'},
      ],
      dateGbn: "ord",
      dateGbnOpt: [
        {value: 'ord', text: '주문일'}
        , {value: 'appr', text: '승인일'}
        , {value: 'pay', text: '결제일'}
        , {value: 'send', text: '전송일'}
        , {value: 'tax', text: '계산서발행일'}
        , {value: 'reg', text: '등록일'}
        , {value: 'upd', text: '수정일'}
      ],
      payTypeOpts: {
        '9999': '주문생성',
        '0000': '결제대기',
        '1000': '현금(계좌)',
        '0001': '쿠폰',
        '0002': '바우처',
        '0010': '포인트',
        '0011': '포인트|쿠폰',
        '0100': '카드',
        '0101': '카드|쿠폰',
        '0110': '카드|포인트',
        '0111': '카드|포인트|쿠폰',
        '2000': '후불',
        '1001': '현금|쿠폰',
        '1010': '현금|포인트',
        '1011': '현금|포인트|쿠폰',
        '1100': '현금|카드',
        '1101': '현금|카드|쿠폰',
        '1110': '현금|카드|포인트',
        '1111': '현금|카드|포인트|쿠폰',
      },
      taxStatOpts: {
        '99': '미발행',
        '01': '영수계산서대상',
        '02': '청구계산서대상',
        '03': '현금영수증대상',
        '04': '영수증발행대상',
        '11': '영수발행',
        '12': '청구발행',
        '13': '현금영수증발행',
        '14': '영수증발행'
      },
      payStatOpts: {'99': '미결제', '10': '부분결제', '20': '초과결제', '00': '결제완료'},
      sendStatOpts: {'99': '미발송', '10': '구매확인', '20': '발송준비', '90': '발송에러', '00': '발송완료'},

      statusOpt: {'00': '활성', '10': '준비', '90': '추출', '99': '만료'},
      logTypeOpts: {'I': '접속', 'O': '종료', 'S': '설치'},
      progCount: 0,
      totalCount: 0,

      rows: [],
      claimLogs: [],
      selectedIdx: null,
      selectedRow: null,
      fromDate: moment().format("YYYY-MM-01"),
      toDate: moment(this.fromDate).endOf("month").format("YYYY-MM-DD"),

      // fromDate: moment().subtract( 7,"days").format('YYYY-MM-DD'),
      // toDate: moment().format('YYYY-MM-DD'),
      /////////////////////////////////////////////////////
      searchWord: null,
      searchField: 'name',
      paramMap: {},

      numOfRecord: 20,

    }

  },
  async created() {
    try {
      this.paramMap['dateGbn'] = this.dateGbn;
      this.paramMap['toDate'] = this.toDate;
      this.paramMap['fromDate'] = this.fromDate;
      await this.getPrdCode();
      await this.getPrdOrderList();
    } catch (err) {
      console.log(err);
    }
  },
  computed: {},

  mounted() {
    console.log("mounted---------------------ProductToken");
    // below is not work!
  },

  methods: {

    setPayDate() {
      // this.toDate =  moment(this.fromDate).endOf("month").format("YYYY-MM-DD");
      this.paramMap['dateGbn'] = this.dateGbn;
      this.paramMap['toDate'] = this.toDate;
      this.paramMap['fromDate'] = this.fromDate;
      // this.getSalesOrderList();
    },

    async getPrdCode() {
      console.log("getPrdCode ---- 1");
      this.prdCodeOpts = {};
      this.prdOpts = [];
      this.prdOpts.push({value: null, text: '주문상품 선택'});
      try {
        const r = await apiCall('get', `/admin/api/product/info`);

        r.result.map(i => {
          this.prdCodeOpts[i.prd_code] = i.disp_name;
          this.prdInfo[i.prd_code] = i;
          this.prdOpts.push({value: i, text: i.prd_code + ' | ' + i.disp_name, disabled: (i.use_yn !== "Y")});
        });
        console.log("prdCodeOpts ----> ", this.prdCodeOpts);
        console.log("prdOpts ----> ", this.prdOpts);
        console.log("prdInfo ----> ", this.prdInfo);

      } catch (err) {
        console.log(err);
      }
    },

    async getPrdOrderList() {
      this.$refs['orderGrid'].clearFilter();
      let r = null;
      let qry = '';
      if (this.searchWord !== null) this.paramMap[this.searchField] = this.searchWord.toUpperCase();
      qry = qs.stringify(this.paramMap);
      console.log("query-string ---------- ", qry);
      try {
        this.rows = [];
        r = await apiCall('get', `/admin/api/sales/product?${qry}`);
        // console.log( "getSalesOrderList ----------> ", r);
        if (r.result) {
          this.rows = r.result;
          await toastSync(this.$bvToast, `${this.rows.length}건 조회됨`, 'primary');
        } else {
          await toastSync(this.$bvToast, `에러: ${r.code} ${r.message}`, 'danger');
        }

      } catch (err) {
        console.log(err);
      }

    },
    setDateGbn() {
      this.paramMap['dateGbn'] = this.dateGbn;
      // this.getSalesOrderList();
    },

    exportExcel() {
      const format = 'xlsx';
      const exportSelectedOnly = true;
      const filename = 'product-order-' + moment().format('YYYY-MM-DD');
      this.$refs["orderGrid"].exportTable(format, exportSelectedOnly, filename);
    },


    async selectSalesOrder(idx, evt) {

      if (!evt) return;
      // console.log( "selectProductToken --------> ", evt );
      //console.log( "selectSalesOrder --------> ", this.$refs['orderGrid'].getSelectedRecords() );

    },

    /**
     *  주문승인 ***
     * */
    async approvalOrder() {
      let params = this.$refs['orderGrid'].getSelectedRecords();
      this.progCount = 0;
      this.totalCount = params.length;
      if (!params.length) {
        await alertWarn(this.$bvModal, {text: "선택한 레코드가 없습니다."});
        return;
      }
      if (!(await alertConfirm(this.$bvModal, `${params.length} 개의 주문을 승인 합니다. 진행 하시겠습니까?`, '주문 승인'))) {
        return;
      }

      let failCnt = 0;
      for (let order of params) {
        console.log("approvalOrder order --------->", order);
        let payType = '0000';
        let taxStat = '03'; // 03:개인, 01:사업자  현금 영수증 대상
        if (order.tax_stat && order.tax_stat !== '99') {
          taxStat = order.tax_stat;
        }

        if (order.appr_yn === 'N') {
          order.prd_name = this.prdInfo[order.prd_code].prd_name; // prd name 세팅
          if (order.pay_stat === '99' && order.unit_price !== 0) {
            if (!(await alertConfirm(this.$bvModal, `미결제 주문(${order.ord_no})을 승인 하시겠습니까? 승인시 후불로 변경되며, 청구계산서를 발행할 수 있습니다.`, '미결제 주문승인'))) {
              failCnt++;
              continue;
            }
            payType = '2000'; // 후불
          }

          if (order.comp_no && order.tax_stat && order.tax_stat === '99') {
            const compNo = order.comp_no.replace(/[^0-9]/g, "");
            if (checkBisNo(compNo)) {
              if (payType === '2000') taxStat = '02'; //청구계산서 발행대상
              else if (payType === '1000') taxStat = '01'; // 영수계산서 발행대상
            }
          }
          order.tax_stat = taxStat;

          const r = await apiCall('POST', `/admin/api/sales/appr-prd-order`, order);
          console.log("approvalOrder----result--->", r);
          if (r.result) {
            this.progCount++;
            order.appr_yn = 'Y';
            order.pay_type = payType;
            order.tax_stat = taxStat;
            order.appr_dt = moment().format("YYYY-MM-DD HH:mm:ss");
          }
        } else {
          failCnt++;
          console.log("====> 기승인 주문: ", order);
        }
      }
      await alertSync(this.$bvModal, {text: `승인: ${this.progCount}건, 실패: ${failCnt}`});
    },

    /**
     *  인증서 전송 ***
     * */
    async sendDocument() {
      let params = this.$refs['orderGrid'].getSelectedRecords();
      this.progCount = 0;
      this.totalCount = params.length;
      if (!params.length) {
        await alertWarn(this.$bvModal, "선택한 레코드가 없습니다.", "레코드를 선택 하세요");
        return;
      }
      if (!(await alertConfirm(this.$bvModal, `${params.length} 개의 주문건에 대한 인증서를 전송합니다. 진행하시겠습니까?`, '인증서 전송'))) {
        return;
      }

      let failCnt = 0;
      for (let order of params) {
        console.log("Send Cert Document order --------->", order);

        let apiUrl = '';

        let pt = order.prd_code.split('-')[0];

        if (   pt.indexOf('PRIM') > -1
            || pt.indexOf('X1S') > -1
            || pt.indexOf('X2S') > -1
            || pt.indexOf('CP1') > -1
            || pt.indexOf('CP2') > -1) {
          apiUrl = `/admin/api/sales/send-subscription`;
        } else {
          apiUrl = `/admin/api/sales/send-cert`;
        }

        if (order.appr_yn === 'Y') {
          order.prd_name = this.prdInfo[order.prd_code].prd_name; // prd name 세팅
          const r = await apiCall('POST', apiUrl, order);
          console.log("sendDocument----result--->", r);
          if (r.result.affectedRows) {
            this.progCount++;
            order.send_yn = 'Y';
            order.send_stat = '00';
            order.send_dt = moment().format("YYYY-MM-DD HH:mm:ss");
          } else {
            console.log("====> 전송실패: ", r);
            order.send_stat = '90';
            failCnt++;
          }
        } else {
          failCnt++;
          console.log("====> 미승인 주문: ", order);
        }
      }
      await alertSync(this.$bvModal, {text: `전송: ${this.progCount}건, 실패: ${failCnt}`});
    },


    async insertSalesOrder() {
      if (!this.selectedPrd) return;
      let r = null;
      let rec = cloneVar(_order);
      // this.$refs.orderGrid.insertSalesOrder( rec );
      // this.rows.push( rec );
      rec.prd_code = this.selectedPrd.prd_code;
      rec.prd_name = this.selectedPrd.prd_name;
      rec.warranty_mon = this.selectedPrd.warranty_mon;
      rec.qty = 1;
      rec.unit_price = this.selectedPrd.unit_price;
      rec.price = rec.unit_price * rec.qty;
      rec.pay_amt = 0;
      // rec.pay_type = '0000';

      rec.ord_dt = moment().format("YYYY-MM-DD HH:mm");
      try {
        r = await apiCall('POST', `/admin/api/sales/product`, rec);
        if (r.result && r.result.insertId) {
          await this.getPrdOrderList();
        }
      } catch (err) {
        console.log(err);

      }

    },

    validateCell(val, oldVal, rec, field) {
      console.log("----------------validateCell-------------------");
      console.log("validateCell---value ---> ", val);
      console.log("validateCell---oldVal ---> ", oldVal);
      console.log("validateCell---rec ---> ", rec);
      console.log("validateCell---field ---> ", field);
      // console.log( "validateCell---",this.$refs['orderGrid'] );
      // console.log( "validateCell---",this.$refs['orderGrid'].selectRecord(0) );
    },

    async updateSalesOrder(rec) {
      let rc = rec[0];
      if (!rc.keys[0]) {
        console.log("nokey");
        return;
      }
      const tIndex = this.$refs['orderGrid'].rowIndex[rc.$id];
      console.log("updateSalesOrder--------- rec[0]|rc --->", rc);
      console.log("updateSalesOrder--------- rec[0].$id--->", rc.$id);
      console.log("updateSalesOrder--------- rec[0].$id--->", this.$refs['orderGrid']);
      console.log("updateSalesOrder--------- rowIndex--->", tIndex);
      let row = this.$refs['orderGrid'].table[tIndex];

      console.log("row ---------> ", row);

      let r = null;

      try {
        let param = {};
        const ordNo = row.ord_no;
        const fieldName = rc.name;
        let newVal = rc.newVal;

        // const row = this.$refs['orderGrid'].currentRecord;
        if ((fieldName === 'prd_code')) {
          row[rc.name] = rc.oldVal;
          return;
        }

        if (row.appr_yn === 'Y') {
          if (!(await alertConfirm(this.$bvModal, "승인된 주문을 수정합니다. 계속하시겠습니까?", '승인 주문 수정'))) {
            row[rc.name] = rc.oldVal;
            return;
          }
        }

        if (fieldName === 'prd_code') {
          let prd = this.prdInfo[newVal];
          console.log("prdInfo ---- ", prd);
          param['price'] = prd.unit_price * row.qty;
          param['unit_price'] = prd.unit_price;
          param['warranty_mon'] = prd.warranty_mon;
          row.price = param['price'];
          row.unit_price = prd.unit_price;
          row.warranty_mon = prd.warranty_mon;
        } else if (fieldName === 'qty') {
          row.price = newVal * row.unit_price;
          param['price'] = row.price;
        } else if (fieldName === "comp_no") {
          const compNo = newVal.replace(/[^0-9]/g, '');
          console.log(compNo);
          if (!checkBisNo(compNo)) {
            await toastSync(this.$bvToast, `사업자번호 검증 실패: ${compNo}`, '사업자번호 체크');
          }
          newVal = compNo;
          row.comp_no = compNo;
        } else if (fieldName === 'pay_type') {
          if (newVal !== '9999' && newVal !== '0000') {
            row.pay_amt = row.price;
            row.pay_stat = '00'; // 결제완료
            row.pay_dt = moment().format('YYYY-MM-DD HH:mm:ss');
            param['pay_amt'] = row.pay_amt;
            param['pay_stat'] = row.pay_stat;
            param['pay_dt'] = row.pay_dt;
          }
        }

        param['ord_no'] = ordNo;
        param[fieldName] = newVal;

        r = await apiCall("PUT", `/admin/api/sales/product`, param);
        console.log(r);


      } catch (err) {
        console.log(err);
      }
    },

    async deleteSalesOrder(recs) {
      // let r = null, msg = null, notice = null;

      try {
        console.log(recs);
        let params = this.$refs['orderGrid'].getSelectedRecords();
        this.progCount = 0;
        this.totalCount = params.length;
        console.log("deleteSalesOrder ----> ", params);
        console.log("deleteSalesOrder length ----> ", params.length);

        const confirmMsg = `${params.length} 개의 데이터를 삭제 합니다. 삭제된 데이터는 복구할수 없습니다. 진행 하시겠습니까?`;

        if (!(await alertConfirm(this.$bvModal, confirmMsg, '주문정보 삭제'))) {
          return;
        }

        // this.$refs['orderGrid'].deleteSelectedRecords();
        let failCnt = 0;
        for (let order of params) {
          console.log("deleteSalesOrder for --------->", order);
          if (order.appr_yn === 'N') {
            const tIndex = this.$refs['orderGrid'].rowIndex[order.$id];
            const r = await apiCall('POST', `/admin/api/sales/delete-prd-order`, order);
            console.log(r);
            if (r.result && r.result.affectedRows === 1) {
              this.progCount++;
              this.$refs["orderGrid"].deleteRecord(tIndex);
            }
          } else {
            failCnt++;
            console.log("====> 기승인 주문 --- skip : ", order);
          }
        }

        await alertSync(this.$bvModal, {text: `삭제: ${this.progCount}건, 실패: ${failCnt}`});
        this.$refs['orderGrid'].clearAllSelected();
        await this.getPrdOrderList();

      } catch (err) {

        console.log(err);
      }
    },

  }
}
</script>
